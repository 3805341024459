define("discourse/plugins/chat/discourse/components/chat/header/icon", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse-common/lib/get-url", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/header/icon/unread-indicator", "discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _concatClass, _dIcon, _getUrl, _discourseI18n, _unreadIndicator, _getUserChatSeparateSidebarMode, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatHeaderIcon = _exports.default = (_class = (_class2 = class ChatHeaderIcon extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
    }
    get showUnreadIndicator() {
      if (this.chatStateManager.isFullPageActive && this.site.desktopView) {
        return false;
      }
      return !this.currentUserInDnD;
    }
    get currentUserInDnD() {
      return this.args.currentUserInDnD || this.currentUser.isInDoNotDisturb();
    }
    get chatSeparateSidebarMode() {
      return (0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(this.currentUser);
    }
    get isActive() {
      return this.args.isActive || this.chatStateManager.isFullPageActive || this.chatStateManager.isDrawerActive;
    }
    get title() {
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never && !this.site.mobileView) {
        return _discourseI18n.default.t("sidebar.panels.forum.label");
      }
      return _discourseI18n.default.t("chat.title_capitalized");
    }
    get icon() {
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never && !this.site.mobileView) {
        return "random";
      }
      return "d-chat";
    }
    get href() {
      if (this.site.mobileView && this.chatStateManager.isFullPageActive) {
        return (0, _getUrl.default)("/chat");
      }
      if (this.chatStateManager.isFullPageActive && !this.chatSeparateSidebarMode.never) {
        return (0, _getUrl.default)(this.chatStateManager.lastKnownAppURL || "/");
      }
      if (this.chatStateManager.isDrawerActive) {
        return (0, _getUrl.default)("/chat");
      }
      return (0, _getUrl.default)(this.chatStateManager.lastKnownChatURL || "/chat");
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <li class="header-dropdown-toggle chat-header-icon">
        <a
          href={{this.href}}
          tabindex="0"
          class={{concatClass "icon" "btn-flat" (if this.isActive "active")}}
          title={{this.title}}
        >
          {{~icon this.icon~}}
          {{#if this.showUnreadIndicator}}
            <ChatHeaderIconUnreadIndicator
              @urgentCount={{@urgentCount}}
              @unreadCount={{@unreadCount}}
              @indicatorPreference={{@indicatorPreference}}
            />
          {{/if}}
        </a>
      </li>
    
  */
  {
    "id": "k6BRcjKb",
    "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"header-dropdown-toggle chat-header-icon\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,0,[\"href\"]]],[14,\"tabindex\",\"0\"],[15,0,[28,[32,0],[\"icon\",\"btn-flat\",[52,[30,0,[\"isActive\"]],\"active\"]],null]],[15,\"title\",[30,0,[\"title\"]]],[12],[1,[28,[32,1],[[30,0,[\"icon\"]]],null]],[41,[30,0,[\"showUnreadIndicator\"]],[[[1,\"          \"],[8,[32,2],null,[[\"@urgentCount\",\"@unreadCount\",\"@indicatorPreference\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@urgentCount\",\"@unreadCount\",\"@indicatorPreference\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/header/icon.js",
    "scope": () => [_concatClass.default, _dIcon.default, _unreadIndicator.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});